<template>
  <div class="el_enterprise">
    <div class="mrow rzqy1">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">入驻企业</h2>
          <div class="en">the display in the company</div>
          <div class="line"></div>
        </div>
        <div class="rzqy1-tabhd">
          <ul>
            <li v-for="(item,index) in tabList"
                :key="index"
                :class="activeTab==item.itemValue?'active':''"
                @click="changeTab(item)">
              {{item.itemText}}
            </li>
          </ul>
        </div>
        <div class="rzqy1-tabbd">
          <ul>
            <li v-for="(item,index) in componeyList"
                :key="index">
              <div class="img">
                <img :src="item.companyLogo" />
              </div>
              <div class="txt">
                <p>{{item.companyIntroduce}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_enterprise',
      activeTab: 1,
      tabList: [
        { index: 1, title: '百强机构' },
        { index: 2, title: '科技服务' },
        { index: 3, title: '猎头' },
        { index: 4, title: '灵活用工' },
        { index: 5, title: '培训咨询' },
        { index: 6, title: '人力资源外包' },
        { index: 7, title: '上市公司' },
      ],
      componeyList: [
      ]
    }
  },
  mounted () {
    this.getCompanyTypeList();
  },
  methods: {
    changeTab (item) {
      this.activeTab = item.itemValue;
      this.getCompanyList(item.itemValue);
    },
    getCompanyTypeList () {//入住机构
      this.api.getCompanyTypeList().then(res => {
        this.tabList = res.data.result;
        this.activeTab = this.tabList[0].itemValue;
        this.getCompanyList(this.tabList[0].itemValue)
      })
    },
    getCompanyList (mainBusiness) {
      this.api.getCompanyList({ pageNo: 1, pageSize: 20, mainBusiness: mainBusiness }).then(res => {
        console.log(res, 765434567)
        this.componeyList = res.data.result.records;
      })
    },
  }
}
</script>
<style scoped lang="scss">
</style>
